import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import newsStyles from './news.module.scss'

const NewsPage = () => {
  const pageData = useStaticQuery(graphql`
    query {
      allContentfulNewsPost {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "dddd, MMMM Do, YYYY")
          }
        }
      }
    }
  `);

  const list = pageData.allContentfulNewsPost.edges.map(edge => {
    const { node } = edge;
    return (
      <li key className={newsStyles.newsEntry}>
        <Link className={newsStyles.newsLink} to={`/news/${node.slug}`}>
          <h2>{node.title}</h2>
          <p>{node.publishedDate}</p>
        </Link>
      </li>
    );
  });
  return (
    <Layout>
      <SEO title="News" />
      <ul>{list}</ul>
    </Layout>
  );
};

export default NewsPage;
