import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import headerStyles from './header.module.scss';

const Header = ({ siteTitle }) => (
  <header className={headerStyles.header}>
    <div className={headerStyles.title}>
      <h1>
        <Link to="/">{siteTitle}</Link>
      </h1>
    </div>
    <nav>
      <ul className={headerStyles.navItems}>
        <li>
          <Link
            to="/"
            className={headerStyles.navItem}
            activeClassName={headerStyles.activeNavItem}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/news"
            className={headerStyles.navItem}
            activeClassName={headerStyles.activeNavItem}
          >
            News
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            className={headerStyles.navItem}
            activeClassName={headerStyles.activeNavItem}
          >
            Contact Us
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className={headerStyles.navItem}
            activeClassName={headerStyles.activeNavItem}
          >
            About Us
          </Link>
        </li>
      </ul>
    </nav>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
